import { css, keyframes } from '@emotion/core';
import styled from '@emotion/styled/macro';
import React, { useState } from 'react';

import { COLORS } from '../../constants';
import { Breakpoint, Qa } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { Heading } from '../UI/Heading';
import { Text } from '../UI/Text';

import ArrowRight from './RightArrow.svg';

type Props = {
  questionList: Qa
};

export function QuestionList({ questionList }: Props) {
  const [activeQuestion, setActiveQuestion] = useState<null | number>();

  function toggleAnswer(index) {
    if (activeQuestion === null) {
      setActiveQuestion(index);
    } else {
      if (activeQuestion === index) {
        setActiveQuestion(null);
      } else {
        setActiveQuestion(index);
      }
    }
  }

  return (
    <Suggestions>
      <SuggestionsHeading type="h2">Last van writer's block?</SuggestionsHeading>
      <Text mode="normal">De volgende vragen helpen je op weg.</Text>
      <Questions>
        {questionList.map((question, index) => {
          return (
            <div key={index} >
              <QuestionContainer >
                <Question mode="normal" onClick={() => toggleAnswer(index)}>
                  <QuestionArrow rotate={activeQuestion === index ? 1 : 0} src={ArrowRight} alt="Question-arrow" />
                  {question.question}
                </Question>
                {activeQuestion === index &&
                  <Answer mode="normal" >{question.answer}</Answer>
                }
              </QuestionContainer>
            </div>
          );
        })}
      </Questions>
    </Suggestions >

  );
}

const Suggestions = styled.div`
  ${forBreakpoint(Breakpoint.Phone, css`
      margin: 0;
  `)}
  ${forBreakpoint(Breakpoint.TabletLandscape, css`
      margin-left: 6rem;
  `)}
`;

const SuggestionsHeading = styled(Heading)`
  color: ${COLORS.DarkGreen};
    ${forBreakpoint(Breakpoint.Phone, css`
      padding-top: 1.5rem;
  `)}
`;

const Questions = styled.div`
  margin-top: 2rem;
  margin-bottom: 6rem;
`;

const QuestionContainer = styled.div`
  display: block;
`;

const Question = styled(Text)`
  display: inline-block;
  cursor: pointer;
  margin-bottom: 1rem;
  user-select: none;
  :hover{
   opacity: 0.8
 }
  
`;

const rotateArrowIn = keyframes`
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(90deg);
  }
`;

const rotateArrowOut = keyframes`
  from{
    transform: rotate(90deg);
  }
  to{
    transform: rotate(0deg);
  }
`;

const QuestionArrow = styled.img<{ rotate: number }>`
  margin-right: 0.5rem;
  animation: ${props => (props.rotate ? css`${rotateArrowIn} 0.1s  forwards` : css`${rotateArrowOut} 0.1s forwards`)} ;
`;

const Answer = styled(Text)`
  display: block;
  color:${COLORS.LightGray};
  margin-top: -0.5rem;
  margin-bottom: 2rem;
  margin-left: 1.2rem;
`;
