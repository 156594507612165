import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import { graphql } from 'gatsby';
import React from 'react';

import { ContactList } from '../components/Contact/ContactList';
import { EmailForm } from '../components/Contact/EmailForm';
import { QuestionList } from '../components/Contact/QuestionList';
import { Root } from '../components/Root';
import { Column } from '../components/UI/Column';
import { Heading } from '../components/UI/Heading';
import { NoSelect } from '../components/UI/NoSelect';
import { Row } from '../components/UI/Row';
import { COLORS } from '../constants';
import BackgroundLetters from '../graphics/BackgroundLetters.svg';
import { Breakpoint } from '../types';
import { forBreakpoint } from '../utils/forBreakpoint';

export const query = graphql`
  query contact {
    sanityContactInfo {
      email
      phone
      street
      number
      city
      postal
      facebookurl
      instagramurl
      linkedinurl
      interests
      questionsAndAnswers {
        question
        answer
    }
    }
  }
`;

export default ({ data }) => {
  const contactInfo = data.sanityContactInfo;
  const questionList = contactInfo.questionsAndAnswers;
  const interestsList = contactInfo.interests;

  return (
    <Root includeFooter={true}>
      <BackgroundImage src={BackgroundLetters} alt="Background letters" />
      <StyledHeading type="h1">Wij zijn er klaar voor. <GrayHeading>Jij ook?</GrayHeading></StyledHeading>
      <ContactListContainer>
        <ContactList contactInfo={contactInfo} />
      </ContactListContainer>
      <StyledRow>
        <Column direction="column" breakpoints={{ [Breakpoint.Phone]: 12, [Breakpoint.TabletLandscape]: 7 }}>
          <EmailForm interestsList={interestsList} />
        </Column>
        <Column direction="column" breakpoints={{ [Breakpoint.Phone]: 12, [Breakpoint.TabletLandscape]: 5 }}>
          <QuestionList questionList={questionList} />
        </Column>
      </StyledRow>
    </Root>
  );
};


const BackgroundImage = styled.img`
  ${NoSelect()};

  ${forBreakpoint(Breakpoint.Phone, css`
    display: none;
  `)}

  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    display: block;
    position: absolute;
    left: -4rem;
    top: -10rem;
    z-index: 0;
  `)};
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 4rem;

  ${forBreakpoint(Breakpoint.Phone, css`
    margin-top: 6rem;
    text-align: center;
    font-size: 2.5rem;
  `)}

  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    text-align: initial;
    font-size: 3.5rem;
  `)}
`;

const GrayHeading = styled.span`
  display: inline-block;
  color: ${COLORS.DarkGreen};

  ${forBreakpoint(Breakpoint.Phone, css`
    font-size: 2.5rem;
  `)}
  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    font-size: 3.5rem;
  `)}
`;

const ContactListContainer = styled.div`
  ${forBreakpoint(Breakpoint.Phone, css`
      margin-bottom: 5rem;
  `)}
  ${forBreakpoint(Breakpoint.TabletLandscape, css`
      margin-bottom: 6rem;
  `)}
`;

const StyledRow = styled(Row)`
    ${forBreakpoint(Breakpoint.TabletLandscape, css`
      margin-bottom: 5rem;
  `)}
`;
