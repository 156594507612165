import styled from '@emotion/styled/macro';
import React from 'react';

import { Text } from '../UI/Text';

export function Thanks() {
  return (
    <Container>
      <Check xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.21 14.13">
        <path fill="#00F2B2" d="M21.21 1.41L19.79 0 8.35 11.45 1.41 4.51 0 5.92l8.2 8.21.21-.21.15.14L21.21 1.41z" />
      </Check>
      <Text mode="normal" >
        Dankuwel! We laten binnenkort iets van ons horen.
      </Text>
    </Container>
  );
}


const Container = styled.div`
  display: flex;
  margin-top: 4rem;
  margin-bottom: 4rem;
`;

const Check = styled.svg`
  flex: 0 0 1rem;
  width: 1rem;
  margin-right: 0.5rem;
`;