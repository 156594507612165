import { css } from '@emotion/core/';
import styled from '@emotion/styled/macro';
import React from 'react';

import { Breakpoint, ContactInfo } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { Column } from '../UI/Column';
import { Row } from '../UI/Row';
import { Text } from '../UI/Text';

import SocialArrow from './SocialArrow.svg';


type Props = {
  contactInfo: ContactInfo
};

export function ContactList({ contactInfo }: Props) {
  return (
    <Container>
      <StyledRow >
        <StyledColumn direction="column" breakpoints={{ [Breakpoint.Phone]: 6, [Breakpoint.TabletLandscape]: 3 }}>
          <StyledContent>
            <StyledText asDiv={true} mode="normal">Contact</StyledText>
            <ContactLink href={`mailto:${contactInfo.email}`}>
              <Text mode="normal">{contactInfo.email}</Text>
            </ContactLink>
            <ContactLink href={`tel:${contactInfo.phone}`}>
              <Text asDiv={true} mode="normal">{contactInfo.phone}</Text>
            </ContactLink>
          </StyledContent>
        </StyledColumn>
        <StyledColumn direction="column" breakpoints={{ [Breakpoint.Phone]: 6, [Breakpoint.TabletLandscape]: 3 }}>
          <StyledContent>
            <StyledText asDiv={true} mode="normal">ADRES</StyledText>
            <Text mode="normal">
              <ContactLink href="https://goo.gl/maps/tUpMzMWvPq8gPW2a6">{contactInfo.street} {contactInfo.number} {contactInfo.postal} {contactInfo.city}</ContactLink>
            </Text>
          </StyledContent>
        </StyledColumn>
        <StyledColumn direction="column" breakpoints={{ [Breakpoint.Phone]: 6, [Breakpoint.TabletLandscape]: 3 }}>
          <StyledContent>
            <StyledText asDiv={true} mode="normal">BTW NUMMER</StyledText>
            <Text mode="normal">BE 0700.610.313</Text>
          </StyledContent>
        </StyledColumn>
        <StyledColumn direction="column" breakpoints={{ [Breakpoint.Phone]: 6, [Breakpoint.TabletLandscape]: 3 }}>
          <StyledContent>
            <StyledText asDiv={true} mode="normal">SOCIAL</StyledText>
            <div>
              <ContactLink href={contactInfo.facebookurl} target="_blank" rel="noopener">
                <Text mode="normal">Facebook </Text> <SocialArrowImg src={SocialArrow} alt="social-arrow" />
              </ContactLink>
            </div>
            <div>
              <ContactLink href={contactInfo.instagramurl} target="_blank" rel="noopener">
                <Text mode="normal">Instagram </Text> <SocialArrowImg src={SocialArrow} alt="social-arrow" />
              </ContactLink>
            </div>
            <div>
              <ContactLink href={contactInfo.linkedinurl} target="_blank" rel="noopener">
                <Text mode="normal">Linkedin </Text> <SocialArrowImg src={SocialArrow} alt="social-arrow" />
              </ContactLink>
            </div>
          </StyledContent>
        </StyledColumn>
      </StyledRow>
    </Container >
  );
}

const Container = styled.div`
  ${forBreakpoint(Breakpoint.Phone, css`
    margin: 0 auto;
  `)}

  ${forBreakpoint(Breakpoint.Desktop, css`
    margin-left: -3rem;
  `)}
`;

const StyledRow = styled(Row)`
  margin-left: -2rem;
  margin-right: -2rem;
`;

const StyledContent = styled.div`
  margin: 0 auto;
  width: 150px;
  margin-bottom: 2rem;
`;

const ContactLink = styled.a`
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  :hover{
   opacity: 0.7
 }
`;

const StyledColumn = styled(Column)`
  padding-bottom: 1rem;
  max-width: 50%;
`;

const StyledText = styled(Text)`
 color: #00F2B2;
 padding-bottom: 0.7rem;
 margin-top: 1rem;
`;

const SocialArrowImg = styled.img`
  margin-right: 4rem;
`;
